import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import ProjectSection from "../components/ProjectSection";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import LinkArrowSvg from "../images/link-arrow";
import Reports from "../components/hire/Reports";
import OurWorkslef from "../components/hire/OurWorkself";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire SaaS Designer`,
    text: "Get the Top 1% SaaS Designers",
    content: "Experience the transformative power of Octet's skilled SaaS designers. They create user-centered interfaces that optimize usability, enhancing your digital products to new heights.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-zone Friendly'
    ],
    linkText: 'Hire SaaS Designers',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire Top SaaS Designers',
    text: "Create a captivating SaaS experience with the expertise of our SaaS designers and outperform your competitors.",
    list: [
      {
        title: 'Cross-platform integration',
        text: 'Hire SaaS designers to construct SaaS solutions that provide your users with a complete experience while ensuring that your product functions seamlessly with various third-party tools and services.'
      },
      {
        title: 'Data-driven approach',
        text: 'Hiring Our best SaaS designers will create a SaaS web design that maximizes user satisfaction and conversion rates by understanding and considering user wants, preferences, and pain areas.'
      },
      {
        title: 'Mobile-responsive design',
        text: 'Hire top SaaS designer from Octet will guarantee that your product reaches its intended audience and provides a consistent user experience across all devices.'
      }
    ],
    image: ["hire-content-saas.webp"],
    titleText: 'Hire SaaS Designers from Octet',
    alt: 'Benefits of hiring SaaS Designers at Octet'
  }
  const commonContent2 = {
    title: 'Why Hire SaaS Experts from Octet?',
    text: "We have assembled a team of incredibly talented designers ready to help you provide your users with a captivating online experience.",
    list: [
      {
        title: 'Cost Efficiency',
        text: 'We use a rigorous verification procedure and quality assurance standards to help your business avoid the risks of employing designers.'
      },
      {
        title: 'Specialized Expertise',
        text: 'We have over 30 professional SaaS designers with different industry knowledge and hands-on experience working on simple to complicated SaaS solutions to address real difficulties.'
      },
      {
        title: 'On-Time Delivery',
        text: 'Our dedicated team of SaaS experts ensures that every project is delivered on schedule, allowing you to fulfill your business objectives without delay.'
      },
      {
        title: 'Collaborative Approach',
        text: 'To shorten the iteration cycle of your product, our SaaS designers communicate openly and collaborate with your in-house team.'
      }
    ],
    image: ["hire-content-saas-2.webp"],
    titleText: 'Benefits of Hiring Saas Designers',
    alt: "Why choose Octet's UI UX Designers"
  }
  const commonContent3 = {
    title: '3 Steps to Hire SaaS Designers',
    text: "Experience the Octet advantage by hiring skilled SaaS designers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: "Provide us with your project requirements, and we will share the resumes of our SaaS designers who best match your needs and business goals."
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled SaaS designers to choose the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire SaaS designer, onboard them in the next 48 hours and dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-saas-3.webp"],
    titleText: 'Steps to hire our SaaS Designers',
    alt: '3 easy steps to hire our SaaS Designers'
  }
  // const commonContent4 = {
  //   title: 'Hire Designers As Per Your Requirement',
  //   text: "At Octet, we empower you to select the perfect designer for your needs with our adaptable hiring options. Pick the model that aligns best with your project requirements",
  //   list: [
  //     {
  //       title: 'Fixed-Cost Hiring',
  //       text: "Ensure your project stays within budget while maintaining transparency and cost-effectiveness. Ideal for projects with defined scopes and timelines."
  //     },
  //     {
  //       title: 'Time & Material Hiring',
  //       text: "Opt for flexibility with this model, where you only pay for the actual hours worked and materials used. Perfect for projects with evolving needs."
  //     },
  //     {
  //       title: 'Full-Time Hiring',
  //       text: "Bring a dedicated designer on board for your ongoing projects. Benefit from consistent support and specialized expertise, fully integrated into your team"
  //     },
  //   ],
  //   image: ["hire-content-saas-4.webp"],
  //   titleText: '',
  //   alt: ''
  // }
  const cta = {
    title: "Hire SaaS Designers to Thrive Your Business!",
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "FinTech SaaS Designers",
      content:
        "With our expert SaaS designers, you can rest assured that your complex financial interfaces will be transformed into an intuitive and user-friendly experience. Our SaaS experts adept skills in simplification guarantee that your users will easily understand and engage with your product.",
    },
    {
      title: "HealthTech SaaS Experts",
      content:
        "Our highly skilled SaaS professionals possess an extensive understanding of the complexities involved in various healthcare areas. Hiring our SaaS designers will deliver unparalleled expertise and attention to detail, ensuring your healthcare needs are met with the utmost care and precision.",
    },
    {
      title: "SaaS Designers for MarTech",
      content:
        "Our team of SaaS designers are masters at creating engaging and user-friendly dashboards that can help boost your campaign's performance. Whether you're looking to increase engagement or optimize your campaigns, we've got you covered!",
    },
  ];
  const tabsData = {
    title: "Hire SaaS Experts with Core Expertise",
    des: `Whether you are a growing startup or an established organization seeking to optimize your software offerings, hiring our dedicated SaaS designers will deliver solutions tailored to your needs.`,
    tabs: ["SaaS MVP Design ", "SaaS Web Design", "SaaS App Design", "Cloud SaaS Integration"],
    slides: [
      {
        title: 'SaaS MVP Design',
        desc1: "Our exceptional team of designers crafts SaaS MVPs by strategically prioritizing essential features.",
        desc2: 'Hire SaaS consultants to design captivating user interfaces and seamlessly integrate feedback mechanisms to ensure optimal user experience.',
        list: [
          'Faster time-to-market for MVPs',
          'Efficiency with cost-effective MVP development',
          'Experienced in market analysis incorporation'
        ]
      },
      {
        title: 'SaaS Web Design',
        desc1: "Our team of expert designers is highly skilled in developing cutting-edge SaaS websites that are responsive and user-friendly.",
        desc2: 'Hiring SaaS designers will help you stay ahead of the latest UX UI trends and guarantee cross-device optimization while simplifying complex operations.',
        list: [
          'Boosting user engagemen',
          'Experience in incorporating interactive elements',
          'Reinforcing a consistent brand identity'
        ]
      },
      {
        title: 'SaaS App Design',
        desc1: "Octet’s SaaS app designs are unmatched in their focus on mobile responsiveness and intuitive interaction. Our expert SaaS designers use advanced design tools to ensure seamless platform consistency.",
        desc2: 'Our SaaS experts analyze user behavior for valuable insights and incorporate features that guarantee unparalleled usability and a world-class user experience.',
        list: [
          'Expertise in simplifying complex operations',
          'Proficient in enhancing digital products',
          'Delivering personalized feature designs'
        ]
      },
      {
        title: 'Cloud SaaS Integration',
        desc1: "Our expert designers are adept at integrating cloud SaaS, focusing on prioritizing teamwork, security, and scalability.",
        desc2: 'Hiring SaaS consultants will help you create intuitive interfaces for hassle-free data access, implement stringent security measures, and design flexible architectures to accommodate user growth.',
        list: [
          'Delivering multi-layered security solutions',
          'Expertise in designing flexible architectures',
          'Proficient in delivering expandable designs'
        ]
      }
    ],
    altOne: 'Mobile App Design by our SaaS Designer for Hire',
    titleOne: 'Mobile App Design',
    altTwo: 'Hire SaaS Designer to create web app design',
    titleTwo: 'Web App Design',
    altThree: 'SaaS Designer for Hire creating responsive website designs',
    titleThree: 'Responsive Website Design',
    altFour: 'Hire SaaS Designer to create wireframes and prototypes',
    titleFour: 'Wireframing and Prototypes',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
        title: "Elastic Sign",
        desc: "Designing empowered by SaaS Research",
        Industry: "SaaS",
        firstAlt: "UI Design to View all the Documents and their Status",
        firstTitle: "Manage Documents UI Design",
        secondAlt: "UI UX Design to Sign Documents Digitally",
        secondTitle: "UI Design to Sign Digital Documents",
        thirdAlt: "UI Screen to draw, upload or type your own signature",
        thirdTitle: "Create Your Own Signature",
        WhatWeDid: [
          {
            title: "User Research",
            link: "/user-research/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "React Development",
            link: "/reactjs-development/",
          },
        ],
        link: "/project/elastic-sign",
        images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
        title: "Hala Gig",
        desc: "SaaS-Enhanced Mobile Accessibility",
        Industry: "SaaS",
        link: "/project/ui-ux-design-halagig/",
        firstAlt: "Mobile UI Design To Collect Customer's Response",
        firstTitle: "Feedback Collection UI Screen",
        secondAlt: "UI Design Of Agent's Account",
        secondTitle: "UI UX Design of Agent's Account",
        thirdAlt: "Mobile App UI UX Design to Onboard Agents",
        thirdTitle: "Onboarding UI UX Screen",
        WhatWeDid: [
            {
            title: "User Research",
            link: "/user-research/",
            },
            {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
            },
            {
            title: "React Development",
            link: "/reactjs-development/",
            },
        ],
        images: ["hala-gig.webp", "hala-gig-2.webp", "hala-gig-3.webp"],
    },
    {
        title: "iGaming",
        desc: "Multi-Channel Monitoring with SaaS Integration",
        Industry: "SaaS",
        firstAlt: "Manage Affilliates",
        firstTitle: "Manage Affilliates",
        secondAlt: "Affilliate Activities",
        secondTitle: "Affilliate Activities",
        thirdAlt: "UI Dashboard to Track Performances",
        thirdTitle: "Dashboard Insights",
        WhatWeDid: [
          {
            title: "User Research",
            link: "/user-research/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
        ],
        link: "/project/igaming/",
        images: ["igaming1.webp", "igaming2.webp", "igaming3.webp"],
    },
    {
      title: "Landscape Pro",
      desc: "Advancing SaaS Application User Experience",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
        title: "Auto Service Booking",
        desc: "Automotive CRM Solution with SaaS Efficiency",
        Industry: "SaaS",
        firstAlt: "UI Screen To Schedule For Car Servicing",
        firstTitle: "Calender",
        secondAlt: "UI Dashboard To Overview Car Servicing Performance",
        secondTitle: "Performance Dashboard",
        thirdAlt: "UI UX Screen to View Company Details",
        thirdTitle: "Company Details",
        WhatWeDid: [
          {
            title: "Product Development",
            link: "/product-development/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "User Acquisition Consultation",
            mobileTitle: "User Consultation",
            link: "/user-acquisition-consulting/",
          },
        ],
        link: "/project/ui-ux-auto-service-booking/",
        images: ["auto-service-web.webp", "auto-service-web-2.webp", "auto-service-web-3.webp"],
    },
  ];
  const reports = {
    title: 'Hire Designers from Octet vs Others',
    text: "Choosing Octet means efficient execution and a speedier ROI. Our SaaS experts are passionate about creating captivating designs and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s SaaS Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'SaaS Design Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const Questions = [
    "What is the difference between SaaS and UI UX designers?",
    "Can I hire SaaS designer for a short-term project?",
    "What is the typical workflow for SaaS experts on a project?",
    "What is the level of expertise of Octet's SaaS consultants?",
    "How is communication handled between clients and SaaS designers?"
  ];
  const faqData = [
    {
      para: [
        `SaaS designers specialize in designing Software as a Service applications, concentrating on scalable architectures, cloud-based solutions, and user engagement strategies.`,
        `UI UX designers, on the other hand, focus broadly on the user interface and experience, optimizing usability and visual aesthetics across various digital platforms.`
      ]
    },
    {
      para: [
        `Yes, you can! At Octet, we offer flexible hiring options tailored to your project's duration.`,
        `Whether it's a brief assignment or a long-term project, we have skilled SaaS designers ready to adapt to your specific needs.`
      ]
    },
    {
      para: [
        `An Octet SaaS designer begins by thoroughly analyzing your project goals, followed by designing a customized strategy.`,
        `This involves user-centric design development, iterative prototyping, user testing, and incorporating feedback, all while maintaining continuous communication with you to ensure alignment with your vision.`
      ]
    },
    {
      para: [
        `Our SaaS designers are highly experienced, with an average of 5+ years in the field.`,
        `They possess deep knowledge in SaaS-specific design, cloud integration, and user retention strategies, ensuring they bring seasoned expertise and cutting-edge design solutions to your project.`
      ]
    },
    {
      para: [
        `We prioritize clear and consistent communication. Clients have direct access to their hired SaaS designers through emails, video calls, and project management tools.`,
        `Regular updates and agile collaboration methods ensure you are fully engaged and informed throughout the design process.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire SaaS Consultants to Empower Excellence",
    para: 'With our extensive experience delivering exceptional results, you can trust us to create designs that exceed your expectations and provide a superior user experience.',
    content: [
      {
        title: "UI UX Designing",
        text: "Our designers specialize in creating user-centered SaaS interfaces that optimize usability and enhance your digital products. Hire SaaS UX consultants to understand your users, conduct in-depth analysis, and provide custom design solutions for increased business success.",
      },
      {
        title: "Usability Testing",
        text: "Usability testing for SaaS products provides insights to improve customer satisfaction. Hire top SaaS designers to identify areas for improvement and obtain actionable recommendations through a transparent, user-centered process.",
      },
      {
        title: "User Research",
        text: "Our SaaS experts provide insights into user needs through surveys, interviews, and testing. They offer actionable solutions to improve UX and design, leading to higher customer satisfaction.",
      },
      {
        title: "UI Development",
        text: "Our designers are experts in creating highly personalized and functional user interfaces using their in-depth knowledge of design principles and industry insights. Hiring our top SaaS experts will provide a customized UI to enhance your users' experience and help your business thrive.",
      },
      {
        title: "Product Development",
        text: "Product development is essential for bringing creative ideas to life, diversifying product offerings, optimizing current products, improving user experience, and more. Hiring our experienced SaaS UX consultants will simplify complex products and create intuitive user experiences.",
      },
      {
        title: "Rapid Prototyping",
        text: "Rapid Prototyping Services will transform your ideas into new actual products. Hiring a dedicated SaaS designer will enable you to improve your company's offerings through early iterations, resulting in better decision-making, higher-quality products, and fewer risks.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What is the difference between SaaS and UI UX designers?",
              "accepted answer": {
                "@type": "Answer",
                "text": "SaaS designers specialize in designing Software as a Service application, concentrating on scalable architectures, cloud-based solutions, and user engagement strategies
          UI UX designers, on the other hand, focus broadly on the user interface and experience, optimizing usability and visual aesthetics across various digital platforms."
              }
            },{
              "@type": "Question",
              "name": "Can I hire SaaS designer for a short-term project?",
              "accepted answer": {
                "@type": "Answer",
                "text": "Yes, you can! At Octet, we offer flexible hiring options tailored to your project's duration.
          Whether it's a brief assignment or a long-term project, we have skilled SaaS designers ready to adapt to your specific needs."
              }
            },{
              "@type": "Question",
              "name": "What is the typical workflow for SaaS experts on a project?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "An Octet SaaS designer begins by thoroughly analyzing your project goals, followed by designing a customized strategy.
          This involves user-centric design development, iterative prototyping, user testing, and incorporating feedback, all while maintaining continuous communication with you to ensure alignment with your vision."
              }
            },{
              "@type": "Question",
              "name": "What is the level of expertise of Octet's SaaS consultants?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our SaaS designers are highly experienced, with an average of 5+ years in the field.
          They possess deep knowledge in SaaS-specific design, cloud integration, and user retention strategies, ensuring they bring seasoned expertise and cutting-edge design solutions to your project."
              }
            },{
              "@type": "Question",
              "name": "How is communication handled between clients and SaaS designers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We prioritize clear and consistent communication. Clients have direct access to their hired SaaS designers through emails, video calls, and project management tools.
          Regular updates and agile collaboration methods ensure that you are fully engaged and informed throughout the design process."
              }
            }]
          }
                   
        `}
      </script>
    </Helmet>
      <Layout hire="Hire Our SaaS Experts">
        <div>
          <HireBanner data={banner} pageName="Hire SaaS Designer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Hire SaaS UX Consultants for Industry Expertise`} text={`Our SaaS consultants have experience developing bespoke software applications tailored to the specific needs of diverse industries and functions.`} />
          <ContentCommon data={commonContent3} big reverse />
          {/* <ContentCommon data={commonContent4} big /> */}
          {/* <OurWorkslef data={ourData} /> */}
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Hire SaaS Designer-Get 3 Days Free Trial"
    description="Hire SaaS designer from Octet to execute your product idea. Get our SaaS design expertise with a 3-day free trial."
  />
);
